import { snakeCase } from 'snake-case'
const hideWhenQuiz = (entityType) => !entityType.object_data?.is_quiz
const hideWhenModule = (entityType) => {
  return !entityType.object_data?.is_module_parent
}

export const CURRENT_USER_ENTITY_TYPE = {
  label: 'Current User',
  name: 'current_user',
  id: -1,
  fields: [{ field_data: { title: 'Name', property: 'name' } }]
}

export const TENANT_LOGIC_BLOCK_ENTITY_TYPE = {
  name: 'system_tenant_logic_block'
}
const filterableOptions = [
  { key: 'none', label: 'None', value: 'None' },
  { key: 'modal', label: 'Filter Modal', value: 'Filter Modal' },
  { key: 'bar', label: 'Filter Bar', value: 'Filter Bar' },
  {
    key: 'modal+bar',
    label: 'Filter Modal + Bar',
    value: 'Filter Modal + Bar'
  }
]
export const supportedEntityTypeProperties = [
  {
    name: 'Label',
    type: 'single-text',
    property: 'label',
    required: true,
    tab: 'setup',
    params: {
      onChanged: (value, entityType) => {
        // we need to update the 'name' property as well.

        entityType.data = { ...entityType.data, name: snakeCase(value) }

        return { ...entityType }
      }
    }
  },
  {
    name: 'Identifier',
    type: 'single-text',
    property: 'name',
    required: true,
    tab: 'setup'
  },
  {
    name: 'Entity Label',
    property: 'label_string',
    type: 'dynamic-text-with-fields',
    params: {
      usesFieldsAsProp: true,
      required: true
      /* mustSaveFirst: true */
    }
  },
  // {
  //   name: 'Search String',
  //   type: 'dynamic-text-with-fields',
  //   property: 'search_string',
  //   params: {
  //     usesFieldsAsProp: true,
  //     /* mustSaveFirst: true */
  //   }
  // },
  {
    name: 'Document Folder',
    type: 'select-list',
    property: 'object_data.document_folder',
    params: {
      sourceName: ['folder']
      /* mustSaveFirst: true */
    }
  },
  {
    name: 'Tree Category',
    type: 'select-list',
    property: 'object_data.tree_category',
    params: {
      sourceName: ['tree_category']
      /* mustSaveFirst: true */
    }
  },
  {
    name: 'Module: Is Parent',
    type: 'boolean',
    property: 'object_data.is_module_parent',
    tab: 'setup'
  },
  {
    name: 'Module: Select Parent',
    type: 'select-entity-type',
    property: 'object_data.module_parent',
    tab: 'setup',
    entityTypeFilter: ['is_module_parent'],
    visible_when: hideWhenModule
  },

  {
    name: 'Subtitle',
    type: 'dynamic-text-with-fields',
    params: {
      usesFieldsAsProp: true,
      required: true
      /* mustSaveFirst: true */
    },
    tab: 'setup',
    property: 'object_data.subtitle'
  },

  {
    name: 'Form Title',
    type: 'dynamic-text-with-fields',
    params: {
      usesFieldsAsProp: true,
      required: true
      /* mustSaveFirst: true */
    },
    tab: 'setup',
    property: 'object_data.formtitle'
  },

  {
    name: 'Sub-Category Label',
    type: 'single-text',
    tab: 'setup',
    property: 'object_data.module_category_header'
  },
  {
    name: 'Icon',
    type: 'icon-picker',
    tab: 'setup',
    property: 'object_data.icon'
  },

  {
    name: 'Tenants',
    type: 'select-tenant-multiple',
    source: 'tenants',
    property: 'tenants',
    tab: 'setup',
    showOnlyWhenGlobal: true,
    required: false
  },
  {
    name: 'Pages',
    type: 'number-entry',
    property: 'pages',
    required: true,
    tab: 'setup',
    default: 1
  },
  {
    name: 'Sort By Field',
    type: 'select-field',
    property: 'object_data.sort_by_field',
    params: {
      usesFieldsAsProp: true,
      required: false
      /* mustSaveFirst: true */
    }
  },
  {
    name: 'Calendar',
    type: 'select-list-multiple',
    property: 'object_data.visible_on_calendar',
    params: {
      sourceName: ['calendar_filter']
      /* mustSaveFirst: true */
    }
  },
  {
    name: 'Calendar Color',
    type: 'color-picker',
    property: 'object_data.calendar_color'
  },
  {
    name: 'Calendar Color Field',
    property: 'object_data.calendar_color_field',
    type: 'select-field',
    params: {
      usesFieldsAsProp: true,
      required: false
      /* mustSaveFirst: true */
    }
  },
  {
    name: 'All day on Calendar',
    property: 'object_data.all_day_entity',
    type: 'boolean',
    params: {
      usesFieldsAsProp: true,
      required: false
      /* mustSaveFirst: true */
    }
  },
  {
    name: 'Start Datetime Field',
    type: 'select-field',
    property: 'object_data.start_datetime_field',
    params: {
      usesFieldsAsProp: true,
      fieldTypeFilter: ['date-time-picker'],
      required: false
      /* mustSaveFirst: true */
    }
  },
  {
    name: 'End Datetime Field',
    type: 'select-field',
    property: 'object_data.end_datetime_field',
    params: {
      usesFieldsAsProp: true,
      fieldTypeFilter: ['date-time-picker'],
      required: false
      /* mustSaveFirst: true */
    }
  },
  {
    name: 'Avatar Field',
    type: 'select-field',
    property: 'object_data.avatar_field',
    params: {
      usesFieldsAsProp: true,
      fieldTypeFilter: ['single-file'],
      required: false
      /* mustSaveFirst: true */
    }
  },
  {
    name: 'Filter Setup',
    type: 'select-filters',
    property: 'object_data.filters_selected',
    params: {
      usesFieldsAsProp: true
    }
  },
  {
    name: 'Filter on tenant',
    type: 'select-adhoc',
    property: 'object_data.filter_on_tenant',
    params: {
      options: filterableOptions
    }
  },
  {
    name: 'Filter on place',
    type: 'select-adhoc',
    property: 'object_data.filter_on_place',
    params: {
      options: filterableOptions
    }
  },
  {
    name: 'Filter on assignee',
    type: 'select-adhoc',
    property: 'object_data.filter_on_assignee',
    params: {
      options: filterableOptions
    }
  },
  {
    name: 'Reporting Concerns',
    helpText:
      'Assign reporting concerns to this entity type for future use in exports.',
    type: 'select-list-multiple',
    property: 'object_data.reporting_concerns',
    params: {
      sourceName: ['entity_type_reporting_concern']
      /* mustSaveFirst: true */
    }
  },
  {
    name: 'Data Sharing Types',
    helpText: 'Notifies the server to process for data sharing.',
    type: 'select-list-multiple',
    property: 'object_data.data_sharing_types',
    params: {
      sourceName: ['entity_type_data_sharing_type']
      /* mustSaveFirst: true */
    }
  },
  {
    name: 'Grouping Key',
    type: 'single-text',
    property: 'grouping_key',
    required: false
  },
  {
    name: 'Admin Module',
    type: 'boolean',
    property: 'object_data.is_global_admin_only',
    required: false,
    tab: 'setup'
  },
  {
    name: 'Is Global',
    type: 'boolean',
    property: 'is_global',
    params: {
      readonly: true
    },
    disabled: true,
    required: false,
    tab: 'setup'
  },

  {
    name: 'Is Quiz',
    type: 'boolean',
    property: 'object_data.is_quiz',
    required: false,
    tab: 'setup'
  },

  {
    name: 'Is Public Form',
    type: 'boolean',
    property: 'object_data.is_public_form',
    helpText:
      'Makes this form available to any person to fill out at /public_forms/identifier (e.g. /public_forms/name_suffix)',
    required: false,
    tab: 'setup'
  },
  {
    name: 'Must Have Tenant ID',
    type: 'boolean',
    property: 'object_data.must_have_tenant_id',
    helpText: 'This form must have a tenant ID to save.',
    required: false,
    has_lower_divider: true,
    tab: 'setup'
  },
  {
    name: 'Sensitive: Show to creator/elevated only',
    type: 'boolean',
    property: 'object_data.hide_unless_creator',
    helpText:
      'Shows these entities only to creator/elevated users. Useful for sensitive data (e.g. timesheets).',
    required: false,
    has_lower_divider: true,
    tab: 'setup'
  },
  {
    name: 'Hide Globals when inside Tenant',
    type: 'boolean',
    property: 'object_data.hide_globals_when_inside_tenant',
    helpText: 'Hides entities with no tenant ID when inside a tenant account.',
    required: false,
    has_lower_divider: true,
    tab: 'setup'
  },

  {
    name: 'Is Module',
    type: 'boolean',
    property: 'is_module',
    required: false,
    visible_when: hideWhenQuiz,
    tab: 'setup'
  },

  {
    name: 'Is Core',
    type: 'boolean',
    property: 'is_core',
    required: false,
    has_lower_divider: true,
    tab: 'setup'
  },

  {
    name: 'Is Task',
    type: 'boolean',
    property: 'is_task',
    required: false,
    has_lower_divider: true,
    visible_when: hideWhenQuiz,
    tab: 'setup'
  },

  {
    name: 'Show Due Date',
    type: 'boolean',
    property: 'date_significant',
    required: false,
    explanation:
      'Select this option to enable date specific UI functionality (e.g. find by date)',
    visible_when: hideWhenQuiz
  },

  {
    name: 'Force Show Assignee',
    type: 'boolean',
    property: 'object_data.show_assignee',
    required: false,
    explanation:
      'Select this option to show the assignee selector. Also displays if this is a task.',
    visible_when: hideWhenQuiz
  },

  {
    name: 'Show Label in table view',
    type: 'boolean',
    property: 'object_data.show_label_table_view',
    required: false,
    explanation: 'Select this option to show the label in the table view.',
    visible_when: hideWhenQuiz
  },

  {
    name: 'Force Show Status',
    type: 'boolean',
    property: 'object_data.show_status',
    required: false,
    explanation:
      'Select this option to show the status selector. Also displays if this is a task.',
    visible_when: hideWhenQuiz
  }
]
