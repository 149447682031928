import { useState, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { sanitiseFilterString } from '../libraries/sanitiseFilterString'
import entityActions, { getFilterString } from '../store/entity/actions'
import { sortListWithEntityType } from '../libraries/entityTools'
import { noData } from '../components/DashboardContainer'
import _ from 'lodash'
import { getSortedEntitiesFromStore } from '../components/HomeContainer'
import {
  DashBoardContext,
  useDashboardContext
} from '../contexts/DashboardContext'
import { useDiscoverEntityEntityType } from './useDiscoverEntityEntityType'

export const useGetWidgetRenderBuckets = (screens, entityTypeId) => {
  const dispatch = useDispatch()

  const { entityType: entityTypeWidgets } = useDiscoverEntityEntityType(
    null,
    null,
    'widget'
  )

  const { entityType: dashboards } = useDiscoverEntityEntityType(
    null,
    null,
    'system_dashboard'
  )
  const state = useSelector((state) => state)
  const dashboardList = getSortedEntitiesFromStore(dashboards, state)

  const { currentDashboard } = useDashboardContext(DashBoardContext)

  const currentDashboardId = useMemo(() => {
    return dashboardList?.data?.[currentDashboard]?.id
  }, [dashboardList, currentDashboard])

  useEffect(() => {
    entityTypeWidgets &&
      dispatch(
        entityActions.listEntity(entityTypeWidgets, {
          ...args,
          filter,

          per_page: 120
        })
      )
  }, [entityTypeWidgets, currentDashboard])

  const args = { detailed: true },
    filter = {}
  const { widgetsWrapper } = useSelector((state) => {
    const filterString = sanitiseFilterString(getFilterString(filter, args))

    const widgetsWrapper =
      entityTypeWidgets &&
      _.get(
        state.entities,
        `[${entityTypeWidgets.name + filterString}]`,
        noData
      )

    if (widgetsWrapper) {
      widgetsWrapper.data = sortListWithEntityType(
        widgetsWrapper.data,
        entityTypeWidgets
      )
    }

    return { widgetsWrapper }
  })

  const thisScreenWidgets = useMemo(() => {
    return (widgetsWrapper?.data ?? []).filter((widget) => {
      const dashboards = Array.isArray(widget.dashboards)
        ? widget.dashboards
        : []
      const dashboardMatch = dashboards.some(
        (dashboard) => Number(dashboard.id) === Number(currentDashboardId)
      )
      const isDashboardScreen = screens.includes('dashboard')
      const isCorrectPosition = screens.includes(widget.position?.key)
      if (isDashboardScreen) {
        return dashboardMatch
      } else {
        return (
          widget.position?.key &&
          isCorrectPosition &&
          (!entityTypeId || widget.entity_list_screen?.id === entityTypeId)
        )
      }
    })
  }, [widgetsWrapper, currentDashboardId])

  const [renderBuckets, setRenderBuckets] = useState([])
  const [progressBarBuckets, setProgressBarBuckets] = useState([])
  const [dashboardProgressBuckets, setDashboardProgressBuckets] = useState([])
  const [responseTimeBuckets, setResponseTimeBuckets] = useState([])
  useEffect(() => {
    let chartBuckets = []
    let tempProgressBarBuckets = []
    let tempDashboardProgressBuckets = []
    let otherWidgetBuckets = []
    let currentOtherWidgetBucket = []
    let tempResponseTimeBuckets = []

    thisScreenWidgets.forEach((widget) => {
      if (
        widget.render_type?.key === 'progress_bar' &&
        widget.position?.key === 'planner'
      ) {
        tempProgressBarBuckets.push([widget])
      } else if (
        widget.render_type?.key === 'chart' &&
        widget.position?.key === 'planner'
      ) {
        tempProgressBarBuckets.push([widget])
      } else if (
        widget.render_type?.key === 'response_time' &&
        widget.position?.key === 'dashboard'
      ) {
        tempResponseTimeBuckets.push([widget])
      } else if (
        widget.render_type?.key === 'chart' ||
        widget.render_type?.key === 'multi_line'
      ) {
        chartBuckets.push([widget])
      } else if (
        widget.render_type?.key === 'progress_bar' &&
        widget.position?.key !== 'planner'
      ) {
        tempDashboardProgressBuckets.push([widget])
      } else if (
        widget.position?.key === 'home_screen' ||
        widget.position?.key === 'view_entity_list'
      ) {
        currentOtherWidgetBucket.push(widget)
      } else {
        otherWidgetBuckets.push(currentOtherWidgetBucket)
        currentOtherWidgetBucket = []
      }
    })

    setRenderBuckets([...chartBuckets, ...otherWidgetBuckets])

    setProgressBarBuckets(tempProgressBarBuckets)

    setDashboardProgressBuckets(tempDashboardProgressBuckets)

    setResponseTimeBuckets(tempResponseTimeBuckets)
  }, [thisScreenWidgets])

  return {
    renderBuckets,
    progressBarBuckets,
    dashboardProgressBuckets,
    responseTimeBuckets
  }
}
