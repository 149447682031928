import React, { useMemo, useState, useEffect } from 'react'
import { View, Text, Image } from 'react-native'
import { WidgetDataLoading } from './WidgetDataLoading'
import * as Progress from 'react-native-progress'
import spacing from '../../styles/spacing'
import fonts from '../../styles/fonts'
import colors from '../../styles/colors'

export const WidgetButtonText = ({ widget, viewData, color }) => {
  const processedColor = useMemo(() => {
    const regex = /^#/
    if (!regex.test(color)) {
      return '#' + color
    }
    return color
  }, [color])

  const formatTimeLabel = (totalSeconds) => {
    const seconds = Number(totalSeconds)
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const remainingSeconds = seconds % 60

    let formattedTime = ''

    if (hours > 0) {
      formattedTime += hours + 'Hr '
    }
    if (minutes > 0) {
      formattedTime += minutes + 'Min '
    }
    if (remainingSeconds > 0) {
      formattedTime += remainingSeconds + 's'
    }

    return formattedTime
  }

  const dataLabel = useMemo(() => {
    const group_function = widget?.group_function?.key ?? 'percentage'
    switch (group_function) {
      case 'response_time':
        const averageResponseTime = viewData.value
        if (averageResponseTime != null) {
          const responseTimeString = averageResponseTime.toString()
          const formattedResponseTime = formatTimeLabel(responseTimeString)
          return `${formattedResponseTime}`
        }
        return

      case 'percentage':
        return `${Math.round((viewData.true / viewData.total) * 100)}%`
      case 'count':
        return `${viewData.true}`
      case 'x_of_y':
        return `${viewData.true}/${viewData.total}`
      case 'maximum':
        return viewData.value
      default:
        console.info(group_function + ' is unhandled.')
        return `${viewData.true}`
    }
  }, [viewData, widget])
  const convertDataToNumber = (viewData) => {
    const render_type = widget?.render_type?.key ?? 'progress_bar'
    if (render_type === 'progress_bar') {
      return viewData.true / viewData.total
    }
  }
  const isPlanner = widget?.position?.key === 'planner'
  const isResponseTime = widget?.render_type?.key === 'response_time'

  const plannerRenderTypes = ['progress_bar']
  const responseTimeRenderTypes = ['response_time']
  const isProgressBar = widget?.render_type?.key === 'progress_bar'

  const widgetImage =
    widget?.render_type?.key === 'response_time' &&
    widget?.SYSTEM_display_picture

  const [imageUri, setImageUri] = useState('')

  useEffect(() => {
    if (responseTimeRenderTypes.includes(widget?.render_type?.key)) {
      setImageUri(widget.SYSTEM_display_picture)
    }
  }, [widget, setImageUri, widgetImage])

  const fontSize = useMemo(() => {
    if (dataLabel) {
      if (dataLabel.length > 12) {
        return 12
      }
      if (dataLabel.length < 4) {
        return 48
      }
      return 36
    }

    return 24
  }, [dataLabel])

  const progressBarFontSize = useMemo(() => {
    if (widget?.name?.length > 12) {
      return 10
    } else if (widget?.name?.length <= 8) {
      return 14
    } else if (widget?.name?.length > 8 < 12) {
      return 12
    }
  }, [widget?.name])

  return !viewData.loaded ? (
    <View>
      <WidgetDataLoading isPlanner={isPlanner} />
      {(isProgressBar || isResponseTime || isPlanner) && (
        <Text
          style={
            isPlanner
              ? {
                  textAlign: 'center',
                  marginRight: spacing.m11,
                  fontWeight: fonts.font500
                }
              : {
                  textAlign: 'center',
                  fontWeight: fonts.font500
                }
          }
        >
          {widget.name}
        </Text>
      )}
    </View>
  ) : (
    <View
      style={{
        minHeight: 72,
        marginTop: isProgressBar && !isPlanner ? spacing.m6 : spacing.m0,
        marginRight: isProgressBar && isPlanner && spacing.m3,
        justifyContent: 'center',
        alignContent: 'center'
      }}
    >
      <Text
        style={{
          fontWeight: fonts.bold,
          textAlign: 'center',
          opacity: 0.75
        }}
      >
        {!isProgressBar && !isResponseTime && dataLabel}
      </Text>
      {plannerRenderTypes.includes(widget?.render_type?.key) && (
        <Progress.Bar
          borderRadius={0}
          width={isProgressBar && !isPlanner ? 125 : 280}
          color={processedColor}
          borderColor={colors.skyLighter}
          unfilledColor={colors.skyLighter}
          style={isPlanner && { marginLeft: spacing.m0 }}
          progress={convertDataToNumber(viewData)}
        />
      )}
      {responseTimeRenderTypes.includes(widget?.render_type?.key) && (
        <View
          style={{
            position: 'absolute',
            width: 205,
            height: 100,
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'visible'
          }}
        >
          <Image
            style={{
              height: 75,
              width: 75,
              marginRight: -45,
              marginTop: -15,
              zIndex: 1
            }}
            source={{
              uri: imageUri
            }}
          />
        </View>
      )}
      {isResponseTime && (
        <Text
          style={{
            position: 'absolute',
            height: 31.8,
            left: -125,
            top: spacing.m2_5 + 2,
            fontFamily: fonts.inter,
            fontWeight: fonts.bold,
            fontSize: fonts.largeLabelSize - 4,
            lineHeight: 40
          }}
        >
          {isResponseTime && dataLabel}
        </Text>
      )}
      <Text
        style={{
          position: 'absolute',
          fontSize: fonts.inputLabelSize,
          fontFamily: fonts.inter,
          fontWeight: fonts.font500,
          width: 125,
          opacity: 0.75,
          top: 55,
          left: -125
        }}
      >
        {isResponseTime && widget.name}
      </Text>
      {isProgressBar && (
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Text
            style={{
              fontSize: isProgressBar ? fonts.moduleLabelSize : fontSize,
              fontWeight: fonts.font500,
              opacity: viewData.fresh ? 1 : 0.5,
              textAlign: 'left'
            }}
          >
            {isProgressBar && dataLabel}
          </Text>
          <Text
            style={{
              fontSize:
                isProgressBar && !isPlanner
                  ? progressBarFontSize
                  : isPlanner
                  ? 14
                  : fontSize,
              fontWeight: isPlanner
                ? fonts.font500
                : isProgressBar && !isPlanner && fonts.bold,
              opacity: viewData.fresh ? 1 : 0.5,
              marginRight: !isPlanner ? spacing.m0 : spacing.m7,
              textAlign: 'right'
            }}
          >
            {widget.name}
          </Text>
        </View>
      )}
    </View>
  )
}
