import React, { useEffect, useMemo, useState } from 'react'
import ModuleButton from './ModuleButton'
import { useDispatch, useSelector } from 'react-redux'
import entityActions from '../store/entity/actions'
import { View } from 'react-native'
import _ from 'lodash'
import { WidgetButtonText } from './WidgetButton/WidgetButtonText'
import { WidgetButtonChart } from './WidgetButton/WidgetButtonChart'
import { Overlay } from 'react-native-elements'
import spacing from '../styles/spacing'
import ModalHeader from './ModalHeader'
import { Chart } from './WidgetButton/Chart'

// TODO: Update to show cached so it feels fast.
// show a loader if no cached.

const WidgetButton = (props) => {
  const dispatch = useDispatch()
  let isPlanner = props.widget?.position?.key === 'planner'
  let isProgressBar = props.widget?.render_type?.key === 'progress_bar'
  let isResponseTime = props.widget?.render_type?.key === 'response_time'

  const { cachedData, targetEntityType } = useSelector((appState) => {
    return {
      cachedData: _.get(
        appState.entities,
        `widgetById.${props.widget.id}.${props.withEntity?.id}`,
        null
      ),
      targetEntityType: _.get(appState, 'entityTypes.data', null).find(
        (et) => et.id == props.widget?.entity_type_target?.id
      )
    }
  })

  const [viewData, setViewData] = useState({ loaded: false, fresh: false })

  const fetchAndUpdateWidget = (id, withEntityId = undefined) => {
    return dispatch(entityActions.showWidget({ id, withEntityId }))
  }

  useEffect(() => {
    if (cachedData) {
      setViewData({ ...cachedData, loaded: true, fresh: false })
    }
  }, [])

  useEffect(() => {
    ;(async () => {
      const widgetData = await fetchAndUpdateWidget(
        props.widget.id,
        props.withEntity?.id
      )
      setViewData({ ...widgetData.data, loaded: true, fresh: true })
      return widgetData
    })()
  }, [props.widget])

  const { widget } = props

  const render_type = props.widget?.render_type?.key

  const widgetUri = useMemo(() => {
    if (targetEntityType) {
      let uri = targetEntityType.uri

      let joiner = '?'
      if (uri.indexOf('?') !== -1) {
        joiner = '&'
      }

      uri += joiner + 'from_widget=' + props.widget.id

      return uri
    }
  }, [targetEntityType, props.widget])

  const [modalShowing, setModalShowing] = useState(false)

  return (
    <>
      <Overlay
        isVisible={modalShowing}
        onBackdropPress={() => setModalShowing(false)}
        overlayStyle={{
          position: 'relative',
          margin: spacing.m0,
          padding: spacing.m0,
          minWidth: '70%',
          maxHeight: '90%'
        }}
      >
        <View style={{ flex: 1 }}>
          <View
            style={{
              flex: 1,
              padding: spacing.m1,
              minHeight: '75%',
              minWidth: '90%'
            }}
          >
            <ModalHeader>{widget.name}</ModalHeader>
            <View
              style={{
                alignContent: 'center',
                justifyContent: 'center',
                flex: 1
              }}
            >
              <Chart viewData={viewData} widget={widget} isPoppedOut />
            </View>
          </View>
        </View>
      </Overlay>
      <ModuleButton
        title={widget.name}
        isResponseTime={isResponseTime}
        isPlanner={isPlanner}
        isProgressBar={isProgressBar}
        onPress={() => setModalShowing(true)}
        to={render_type === 'chart' ? undefined : widgetUri}
        imageComponent={
          render_type === 'chart' || render_type === 'multi_line' ? (
            <WidgetButtonChart widget={props.widget} viewData={viewData} />
          ) : (
            <WidgetButtonText
              widget={props.widget}
              color={props.color}
              viewData={viewData}
            />
          )
        }
      />
    </>
  )
}

export default WidgetButton
