import _ from 'lodash'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SelectWithData from './SelectWithData'
import tenantActions from '../../store/tenant/actions'

//actions

export const SelectTenant = (props) => {
  const dispatch = useDispatch()

  const tenantsListRoot = useSelector((state) =>
    _.get(state, `tenants.tenants`, { data: [] })
  )

  // we will just always reload tenants - not exp check
  useEffect(() => {
    dispatch(tenantActions.listTenants())
  }, [])

  const data = tenantsListRoot.data?.map((t) => {
    t.label = t.display_name + ' (' + t.id + ')'
    return t
  })
  return (
    <SelectWithData
      {...props}
      dataRows={data}
      linkStyle={true}
      value={'' + props.defaultValue}
      labelField={'label'}
      onChangeText={(value, userInitiated) => {
        props.onChangeText(value?.id ?? null, userInitiated)
      }}
    />
  )
}
