import { DateTime } from 'luxon'
const defaultState = {
  errors: {
    errors: null,
    hasError: false
  }
}

export default function tenants(state = defaultState, action) {
  switch (action.type) {
    case 'tenant/list-started':
      {
        state = {
          ...state,
          tenants: {
            ...state.tenants,
            lastRetrieveStarted: DateTime.local()
          }
        }
      }

      break
    case 'tenant/list-success':
      {
        const { response } = action

        state = {
          ...state,
          ...{
            tenants: {
              ...state.tenants,
              data: response.data,
              lastRetrieveFinished: DateTime.local()
            }
          }
        }
      }

      break
    case 'tenant/upsert-started':
    case 'tenant/upsert-success':
      state.errors = {
        ...state.errors,
        ...{ hasError: false, message: null, statusCode: null, errors: null }
      }
      break

    case 'tenant/upsert-error':
      state.errors = {
        errors: action.error.errors,
        message: action.error.message,
        statusCode: action.error.statusCode,
        hasError: true
      }
      break
    case 'tenant/clear-general-error':
      state.errors = {
        ...state.errors,
        ...{ message: null, statusCode: null }
      }
      break
  }

  return state
}
