import React, { useEffect, useMemo, useState } from 'react'
import WithHeader from '../components/WithHeader'
import { Platform, View } from 'react-native'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'

import { ListItemLinkButton } from '../components/ListItemLinkButton'
import { ListItem } from 'react-native-elements'

import webVersion from '../version.web.json'
import colors from '../styles/colors'
import { requestGlobalToken, selectTenant } from '../store/user/actions'
import { useTenantOrGlobalAdmin } from '../hooks/useTenantOrGlobalAdmin'
import { roles } from '../components/fields/MultipleRolePicker'
import { showMessage } from 'react-native-flash-message'
import MSFESButton from '../components/MSFESButton'
import spacing from '../styles/spacing'

const codePush =
  Platform.OS !== 'web' ? require('react-native-code-push') : null

export default function SettingsIndexScreen({ navigation }) {
  const pageTitle = 'Settings'

  const { current_user } = useSelector((state) => {
    return { current_user: _.get(state, 'users.user') }
  })

  const hasAdminToolsPermission = _.get(
    current_user.effective_permissions,
    'view||name:admin_modules',
    false
  )

  const hasPermissionModdingPermission =
    _.get(
      current_user.effective_permissions,
      'update||name:tenant-level-permissions',
      false
    ) ||
    _.get(
      current_user.effective_permissions,
      'update||name:privileged-permissions',
      false
    )

  const hasCollectionsPermission = _.get(
    current_user.effective_permissions,
    'update||name:collections',
    false
  )
  const environment = useSelector((state) => {
    return state.app.environment
  })

  const [versionInformation, setVersionInformation] = useState({
    label: '',
    version: '',
    description: ''
  })

  useEffect(() => {
    try {
      if (codePush) {
        codePush
          .getUpdateMetadata()
          .then((metadata) => {
            if (metadata) {
              setVersionInformation({
                label: metadata.label,
                version: metadata.appVersion,
                description: metadata.description
              })
            }
          })
          .catch(() => {})
      }
    } catch {}
  }, [])

  let versionLabel = useMemo(() => {
    let versionLabel = environment + ''

    if (versionInformation.label) {
      let hash = versionInformation.description
        .split(' ')
        .slice(-1)[0]
        .substr(0, 8)
      versionLabel += `/${versionInformation.label}/build ${versionInformation.version}/${hash}`
    } else {
      versionLabel += '/' + webVersion.hash
    }

    return versionLabel
  }, [versionInformation.label, environment, webVersion])

  const [isClearingCache, setIsClearingCache] = useState(false)
  const { isGlobalAdmin, actingRole, tenant } = useTenantOrGlobalAdmin()

  const dispatch = useDispatch()

  return (
    <WithHeader title={pageTitle} navigation={navigation}>
      <View key="view-module-wrapper">
        <View>
          {hasAdminToolsPermission && (
            <ListItemLinkButton
              title={'Admin Tools'}
              to={`/settings/admin-tools/index`}
            />
          )}
          {hasPermissionModdingPermission && (
            <ListItemLinkButton
              title={'Permissions Editor'}
              to={`/settings/admin-tools/permissions`}
            />
          )}
          {hasCollectionsPermission && (
            <ListItemLinkButton
              title={'Collections'}
              to={`/settings/collections`}
            />
          )}
          <ListItemLinkButton
            title={'Reports'}
            to={`/settings/reports/index`}
          />

          <ListItem>
            <ListItem.Title style={{ color: colors.labelGray }}>
              About: {versionLabel} API: {current_user.api_version}
            </ListItem.Title>
          </ListItem>

          <MSFESButton
            title="Clear Cache"
            icon={{ name: 'refresh' }}
            containerStyle={{
              padding: spacing.m1,
              flex: 1,
              minHeight: 44
            }}
            disabled={isClearingCache}
            onPress={() => {
              setIsClearingCache(true)

              const onSuccess = () => {
                showMessage({
                  message: 'Cache cleared.',
                  type: 'success'
                })
                if (Platform.OS === 'web') {
                  window.location.reload()
                } else {
                  const codePush = require('react-native-code-push')
                  codePush.restartApp()
                }
              }
              const onFinally = () => {
                setIsClearingCache(false)
              }

              dispatch({
                type: 'entity/reset'
              })

              if (isGlobalAdmin) {
                dispatch(requestGlobalToken())
                  .then(onSuccess)
                  .finally(onFinally)
              } else {
                const role = roles.find((r) => r.key === actingRole)
                dispatch(selectTenant({ tenant, role }))
                  .then(onSuccess)
                  .finally(onFinally)
              }
            }}
          />
        </View>
      </View>
    </WithHeader>
  )
}
