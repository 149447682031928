import React from 'react'
import { View, Image, useWindowDimensions } from 'react-native'
import spacing from '../styles/spacing'
import MSFESHeading from './MSFESHeading'


const TenantBrandHeader = ({ tenant }) => {

  const { width } = useWindowDimensions();

  const imageSize = width > 500 ? 72 : 36
  
  return tenant && <View style={{ flexDirection: "row", marginBottom: spacing.m2 }}>
  <Image source={{ uri: tenant?.SYSTEM_display_picture }} style={{ width: imageSize, height: imageSize }} resizeMode={"contain"} />
  <View style={{ justifyContent: "center", flex: 1, paddingLeft: spacing.m1 }}>
    <MSFESHeading>{tenant?.display_name}</MSFESHeading>
  </View>
</View>
}

export default TenantBrandHeader